import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { StaticQuery, graphql } from 'gatsby'
import './../css/index.css'
import ChefOG  from "./../assets/img/lekker-game-chef-og.jpg"

const Layout = ({ children }) => (
  <StaticQuery
    query={graphql`
      query SiteTitleQuery {
        site {
          siteMetadata {
            title
          }
        }
      }
    `}
    render={data => (
      <div class="relative">
        <div className="blue-pink-overlay w-full z-50 absolute pointer-events-none"></div>
        <Helmet
          title={data.site.siteMetadata.title}
          meta={[
            { name: 'description', content: 'lekker.games offers personalized video game recommendations. find multiplayer and singleplayer pc games that match your taste in games. just enter your steamID and get some new games. expand your steam library.' },
            { name: 'keywords', content: 'recommend, games, steam, lekker, lecker, videogames, taste, singleplayer, multiplayer,pc, pc games, library' },
            { property: 'og:url', content: "https://lekker.games"  },
            { property: 'og:image', content: ChefOG  },
            { property: 'og:title', content: "LEKKER.GAMES"  },
            { property: 'og:description', content: "lekker.games offers personalized video game recommendations. just enter your steamID and get some delicious games. find new multiplayer and singleplayer pc games that match your taste in games."  },
          ]}
        >
          <html lang="en" />
        </Helmet>
        <div
          style={{
            maxWidth: "100%",
          }}
        >
          {children}
        </div>
      </div>
    )}
  />
)

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
