import React from 'react'

import { Link } from "gatsby"
import Layout from '../components/layout'
import Testemonial from "../components/testemonial"
import Helmet from "react-helmet"

import ChefHero from './../assets/img/chef-hero-small.png'
import PileOfGames from "./../assets/img/pile-of-games.svg"
import HowItWorksTitle from "./../assets/img/how-it-works.svg"
import ComingSoon from "./../assets/img/coming-soon.svg"
import Year2019  from "./../assets/img/2019.svg"
import IconEmail  from "./../assets/img/icon-email-pixel.svg"
import WhatIsLekker from "./../assets/img/what-is-lekker.png"

//testimonial images
import TestimonialWalter  from "./../assets/img/testemonials/walter.svg"
import TestimonialClark  from "./../assets/img/testemonials/clark.svg"
import TestimonialCreep  from "./../assets/img/testemonials/creep.svg"
import TestimonialTrooper  from "./../assets/img/testemonials/trooper.svg"
import TestimonialSnake  from "./../assets/img/testemonials/snake.svg"

//icon images
import InstagramIcon from "./../assets/img/social/instagram.svg"
import TwitterIcon from "./../assets/img/social/twitter.svg"
import YouTubeIcon from "./../assets/img/social/youtube.svg"
import VimeoIcon from "./../assets/img/social/vimeo.svg"
import FacebookIcon from "./../assets/img/social/facebook.svg"

const IndexPage = () => (
  <Layout>

    <Helmet
      meta={[
        { name: 'google-site-verification', content: '-mAhAjHNf58MQVuJsNR80KZl_utfWGgN7gy2x8B45B4' },
        { name: 'google-site-verification', content: 'keiq3_0bBI7-BK8agwjk0Xeyti43Fao41SX6iAt1ztQ' },
        { name: 'google-site-verification', content: 'wuK0pcghY_3r_hZ2NxTXXkY1ThLEJvdu7HmQd1Bx5sU' },
      ]}
      link={[
        { rel: "canonical", href:"https://lekker.games/"}
      ]}
    />

    <section class="hero-section gradient-hero flex flex-wrap flex-col-reverse md:flex-row  angled-edge-bottom-right-high">

      <div class="w-full flex justify-center relative mt-8 md:mt-0 md:w-1/2 md:block">
        <img class="mt-2 self-center w-1/2 h-auto static position-chef z-20 md:absolute md:w-2/3 md:max-w-sm" src={ChefHero} alt="Chef" title="this chef cooks all the good game recommendations"/>
      </div>

      <div className="w-full flex items-center flex-col md:w-1/2 pt-12 md:block">

        <div class="main-title__container mb-8">
          <h1 className="font-title text-white tracking-wi uppercase mb-2 ml-2 inline-flex flex-col md:text-left text-center">
            <span className="tracking-wide inline-block main-title-medium mb-3">Find new</span>
            <a class="text-white lekker-hover-special tracking-wider main-title-huge mb-3 no-underline" href={WhatIsLekker} target="_blank" rel="noopener noreferrer">lekker<span class="question-mark hidden ml-2"> ?</span></a>
            <span className="tracking-wide inline-block main-title-medium"> pc games</span>
          </h1>

        </div>

        <a href="/#comingsoon" className="inline-block no-underline font-subtitle text-white text-small-text bg-lazer-pink py-3 px-8">find new games!
        </a>

      </div>
    </section>

    <section className="why-section bg-grey-darkest flex flex-wrap items-center pt-48 pb-20">

      <div className="p-6 md:pl-24 w-full md:w-2/3 flex flex-col items-center">
        <p class="mb-6 md:mb-1 text-text font-text text-white">Tired of <span class="text-subtitle font-bold">always</span> playing the <span
          className="text-subtitle font-bold">same games?</span></p>
        <p class="mb-6 md:mb-1 text-text font-text text-white"><span className="text-subtitle font-bold">Too many games</span> to choose from?</p>
        <p class="mb-6 md:mb-1 text-text font-text text-white">How to find the <span className="text-subtitle font-bold">good games?</span></p>
      </div>

      <div className="w-full md:w-1/3 md:block flex justify-center items-start">
        <img class="p-0 w-1/2 md:pr-6 md:w-full" src={PileOfGames} alt="a pile of games" title="a pile of floppy discs. if you still know them, you're probably old ;)"/>
      </div>
    </section>

    <section className="how-section gradient-how flex flex-wrap items-center flex-col py-12">

      <img class="w-3/4 md:w-1/2 mb-20" src={HowItWorksTitle} alt="How it works" />

      <div class="how-steps__container px-8 pb-0 w-full md:w-1/3 md:p-0 md:pl-0 ">

        <div class="how-single-step mb-12 flex flex-row">
          <div className="w-1/8 text-white text-subtitle-contrast font-title mr-6">1.</div>
          <div class="how-content__container w-7/8 mt-4">
            <h3 className="text-white-light text-subtitle font-title tracking-extra-wide mb-3">Papers Please</h3>
            <div className="how-text__container">
              <p className="text-white font-text text-text tracking-wide">Tell me your steamID.</p>
              <p className="text-white font-text text-text tracking-wide">So i know what games you play.</p>
            </div>
          </div>
        </div>

        <div className="how-single-step mb-12 flex flex-row">
          <div className="w-1/8 text-white text-subtitle-contrast font-title mr-6">2.</div>
          <div className="how-content__container w-7/8 mt-4">
            <h3 className="text-white-light text-subtitle font-title tracking-extra-wide mb-3 uppercase">you're wrong!</h3>
            <div className="how-text__container">
              <p className="text-white font-text text-text tracking-wide">Check if i am correct about predicting your taste in games.</p>
            </div>
          </div>
        </div>

        <div className="how-single-step mb-16 flex flex-row">
          <div className="w-1/8 text-white text-subtitle-contrast font-title mr-6">3.</div>
          <div className="how-content__container w-7/8 mt-4">
            <h3 className="text-white-light text-subtitle font-title tracking-extra-wide mb-3 uppercase">you got served</h3>
            <div className="how-text__container">
              <p className="text-white font-text text-text tracking-wide">I serve you lekker games!</p>
            </div>
          </div>
        </div>
      </div>

      <Link to="/#comingsoon" className="inline-block no-underline font-subtitle text-white text-small-text bg-lazer-pink py-3 px-8 mb-16">find lekker games!
      </Link>

    </section>

    <section class="bg-pink-very-dark flex flex-col items-center w-full pt-12 pb-24">
      <div class="w-4/5 md:w-2/3">
        <Testemonial props={{
          image: TestimonialClark,
          message:"lekker.games is my kryptonite",
          name:"Clark K.",
          profession:"Just a regular reporter, Metropolis",
        }}/>
        <Testemonial props={{
          image: TestimonialWalter,
          message:"lekker.games is as easy as cooking meth!",
          name:"Walter W.",
          profession:"Teacher from Albuquerque, New Mexico",
        }}/>
        <Testemonial props={{
          image: TestimonialCreep,
          message:"Pfffssssssssssssssshttt",
          name:"Creeper C.",
          profession:"A good friend of steve",
        }}/>
        <Testemonial props={{
          image: TestimonialTrooper,
          message:"lekker.games significantly improved my aim",
          name:"Stormtrooper",
          profession:"Famous head-bumping stormtrooper, Death Star",
        }}/>
        <Testemonial props={{
          image: TestimonialSnake,
          message:"lekker.games is almost as fast as a game of “snake”",
          name:"Snek S.",
          profession:"Main character from “Snake”",
        }}/>
      </div>
    </section>

    <section id="comingsoon" class="when-section gradient-when
    flex flex-wrap items-center flex-col pt-16 pb-64 px-12">
      <img class="mb-6" src={ComingSoon} alt="coming soon" title="coming soon"/>
      <img class="mb-16" src={Year2019} alt="2019" title="year 2019"/>
      <a href="https://emailoctopus.com/lists/525ff49a-e67a-11e8-a3c9-06b79b628af2/forms/subscribe" className="inline-block no-underline font-subtitle text-white text-small-text bg-lazer-pink py-3 px-8 flex items-center">tell me when its ready <img class="w-5 ml-3" src={IconEmail} alt="email icon"/>
      </a>
    </section>

    <footer class="bg-grey-darkest w-full p-8">
      <div class="flex flex-wrap justify-center items-center">
        <p className="text-white text-small-text font-text mr-auto mb-6 md:mb-0">What the hell does <a href={WhatIsLekker} target="_blank" rel="noopener noreferrer">"LEKKER"</a> mean?</p>
        <p className="text-left font-subtitle text-small-text text-grey-darker inline-block mr-auto mb-6 md:mb-0">lekker.games by © 2018 DATADEER</p>
        <div className="ml-12 social-buttons__container inline-block mb-6 md:mb-0">
          <a href="https://www.instagram.com/lekker.games/"><img className="w-5 mr-6" src={InstagramIcon} alt="instagram" rel="noopener noreferrer"/></a>
          <a href="https://www.facebook.com/lekkergames-301409844044660/"><img className="w-5 mr-6" src={FacebookIcon} alt="facebook" rel="noopener noreferrer"/></a>
          <a href="https://twitter.com/lekker_games"><img className="w-5 mr-6 md:mb-0" src={TwitterIcon} alt="twitter" rel="noopener noreferrer"/></a>
        </div>
      </div>

    </footer>
  </Layout>
)

export default IndexPage
