import React from 'react'


const Testemonial = ({props}) => (
  <article class="bg-grey-darkest p-12 mb-5 flex flex-wrap shadow-small md:py-12 md:px-6 overflow-hidden">
    <div class="flex items-center justify-center w-full md:w-1/4 mb-8 md:mb-0">
      <img class="w-24" src={props.image} alt={props.name}/>
    </div>
    <div class="w-full p-0 flex flex-col justify-center word-wrap md:p-2 md:w-3/4">
      <h4 class="mb-2 text-text text-white-light font-text font-normal tracking-wide md:text-subtitle">{props.message}</h4>
      <p class="text-white-lighter text-text font-text tracking-wide">{props.name}</p>
      <p class="text-white-lighter text-small-text font-text">{props.profession}</p>
    </div>

  </article>
)

export default Testemonial
